import React, { useRef, useEffect } from 'react';
import styles from './Page.module.scss';

const Page = ({ title, children }) => {
  const pageRef = useRef();

  useEffect(() => {
    const index = window.location.href.indexOf('#');
    if (index === -1) {
      // Only scroll to the start of the page if there is no fragment.
      pageRef.current.scrollIntoView();
    } else {
      // Normal scrolling to the fragment doesn't always work due to
      // the page not being complete at the start. So we scrollIntoView again
      // after this Page component is mounted.
      const fragmentId = window.location.href.slice(index + 1);
      const element = document.getElementById(fragmentId);
      if (element) {
        element.scrollIntoView();
      }
    }
    // empty deps list means this effect only runs once on mount
  }, []);

  return (
    <div ref={pageRef} className={styles['page']}>
      <div className={styles['page__inner']}>
        { title && <h1 className={styles['page__title']}>{title}</h1>}
        <div className={styles['page__body']}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Page;